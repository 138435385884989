import PostCard from "@components/PostCard"
import { posts } from "@components/PostCard/mock"
import PostModal from "@components/PostModal"
import TranslateWrapper from "@components/TranslateWrapper"
import { Col, Row } from "antd"
import _map from "lodash/map"
import React, { useEffect, useState } from "react"

const PostPage = () => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState()
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleClick = (props) => {
    setVisible(true)
    setData(props)
  }

  const handleCloseModal = () => {
    setVisible(false)
  }

  if (!isClient) return null

  return (
    <TranslateWrapper>
      <div className="bg-light-silver center vh-100">
        <Row>
          {_map(posts, (item) => {
            return (
              <Col span={24} xl={7}>
                <PostCard
                  item={item}
                  handleCloseModal={() => handleCloseModal}
                  handleShowComments={() => handleClick(item)}
                />
              </Col>
            )
          })}
        </Row>
      </div>
      <PostModal
        visible={visible}
        handleCloseModal={handleCloseModal}
        item={data}
      />
    </TranslateWrapper>
  )
}

export default PostPage
