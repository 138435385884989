import "dayjs/locale/pt-br"

import {
  getDayFromDate,
  getMonthFromDate,
  getOnlyDigits,
  getYearFromDate,
} from "@utils/strings"
import dayjs from "dayjs"
import _get from "lodash/get"
import { v4 as uuidv4 } from "uuid"

export const SubscriberStatusEnum = {
  DELETED: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  OPENED: 3,
}

export const PaymentMethodEnum = {
  CREDIT_CARD: "CREDIT_CARD",
  BILLET: "BOLETO",
  PIX: "PIX",
}

export const createSignatureAndSubscriberWirecardAdapter = (payload) => {
  const { selectedPlan, registerFormValues, paymentFormValues, codeCoupon } =
    payload
  const { code, amount, name } = selectedPlan
  const { paymentMethod } = paymentFormValues
  const {
    identityDocument,
    documentNumberCPF,
    documentNumberPassport,
    documentNumberOthers,
    fullName,
    phoneNumber,
    dateOfBirth,
    email,
    address,
  } = registerFormValues

  let customerCode

  switch (identityDocument) {
    case "CPF":
      customerCode = getOnlyDigits(documentNumberCPF)
      break
    case "Passaporte":
      customerCode = documentNumberPassport
      break
    case "Outros":
      customerCode = documentNumberOthers
      break
  }

  const signatureCode = uuidv4()

  const birthdateDay = getDayFromDate(dateOfBirth)
  const birthdateMonth = getMonthFromDate(dateOfBirth)
  const birthdateYear = getYearFromDate(dateOfBirth)

  const creditCardName = _get(paymentFormValues, "nameCard", "")
  const creditCardNumber = _get(paymentFormValues, "cardNumber", "")
  const dueDate = _get(paymentFormValues, "dueDate", "")

  const areaCode = phoneNumber.substring(2, 4)
  const phoneNumberOnly = phoneNumber.substring(4)

  const expirationMonth = dueDate.substring(
    dueDate.lastIndexOf(0),
    dueDate.lastIndexOf("/")
  )
  const expirationYear = dueDate.substring(dueDate.lastIndexOf("/") + 1)

  const billingInfo =
    paymentMethod === PaymentMethodEnum.CREDIT_CARD
      ? {
          billing_info: {
            credit_card: {
              holder_name: creditCardName,
              number: getOnlyDigits(creditCardNumber),
              expiration_month: expirationMonth,
              expiration_year: expirationYear,
            },
          },
        }
      : {}

  return !codeCoupon
    ? {
        code: signatureCode,
        amount: `${amount}`,
        payment_method: paymentMethod,
        plan: {
          name: name,
          code,
        },
        customer: {
          code: signatureCode,
          email,
          fullname: fullName,
          cpf: customerCode,
          phone_number: phoneNumberOnly,
          phone_area_code: areaCode,
          birthdate_day: birthdateDay,
          birthdate_month: birthdateMonth,
          birthdate_year: birthdateYear,
          address,
          ...billingInfo,
        },
      }
    : {
        code: signatureCode,
        amount: `${amount}`,
        payment_method: paymentMethod,
        plan: {
          name: name,
          code,
        },
        customer: {
          code: signatureCode,
          email,
          fullname: fullName,
          cpf: customerCode,
          phone_number: phoneNumberOnly,
          phone_area_code: areaCode,
          birthdate_day: birthdateDay,
          birthdate_month: birthdateMonth,
          birthdate_year: birthdateYear,
          address,
          ...billingInfo,
        },
        coupon: {
          code: codeCoupon,
        },
      }
}

export const createSubscriberAnonimattaAdapter = (payload) => {
  const {
    fullName,
    nickname,
    codeNumber,
    phoneNumber,
    dateOfBirth,
    identityDocument,
    documentNumberCPF,
    documentNumberPassport,
    documentNumberOthers,
    newPassword,
    filePath,
    username,
    address,
    status,
    email,
  } = payload

  const nick = nickname ? { nickname } : {}

  let documentNumber

  switch (identityDocument) {
    case "CPF":
      documentNumber = documentNumberCPF
      break
    case "Passaporte":
      documentNumber = documentNumberPassport
      break
    case "Outros":
      documentNumber = documentNumberOthers
      break
  }

  return {
    name: fullName,
    ...nick,
    phoneNumber: getOnlyDigits(`${codeNumber}${phoneNumber}`),
    dateBirth: dayjs(dateOfBirth, "DD/MM/YYYY"),
    documentType: identityDocument,
    documentNumber,
    password: newPassword,
    urlPhoto: filePath,
    language: "Português",
    status,
    username,
    address,
    email,
  }
}

export const createSubscriberAnonimattaAdapterNew = (payload) => {
  const {
    fullName,
    nickname,
    codeNumber,
    phoneNumber,
    dateOfBirth,
    documentNumberCPF,
    newPassword,
    filePath,
    username,
    status,
    email,
  } = payload

  const nick = nickname ? { nickname } : {}

  let documentNumber = documentNumberCPF

  return {
    name: fullName,
    ...nick,
    phoneNumber: getOnlyDigits(`${codeNumber}${phoneNumber}`),
    dateBirth: dayjs(dateOfBirth, "DD/MM/YYYY"),
    documentType: "CPF",
    documentNumber,
    password: newPassword,
    urlPhoto: filePath,
    language: "Português",
    status,
    username,
    email,
  }
}

export const errorsAdapter = (errorMessage) => {
  if (errorMessage) {
    return [
      {
        description: errorMessage,
      },
    ]
  }

  return []
}
