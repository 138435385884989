import dayjs from "dayjs"

export const posts = [
  // {
  //   affiliated: "Valterson Filho",
  //   affiliatedId: 186,
  //   comments: "0",
  //   datePublication: "2021-02-15T20:49:48.745Z",
  //   id: 69,
  //   likes: "0",
  //   postText: "",
  //   status: 1,
  //   type: 2,
  //   urlImage: null,
  //   urlProfile: "images/banner/1etfhg77u-avatar.jpeg",
  //   urlVideo: "https://vimeo.com/115394948",
  // }
  {
    affiliated: "Ana Barreto", // affiliated
    likes: 100,
    category: "nudes",
    urlProfile: require("@images/emily.jpg"), // urlProfile
    urlImage: require("@images/emily.jpg"), // urlImage
    urlVideo: "", // urlVideo
    postText: "Lorem ipsum dolor sit amet consectetur", // postText
    // comments: [
    //   {
    //     id: 1,
    //     author: "Pedro Campos",
    //     comment: "Ficou ótimo!",
    //     responses: [
    //       {
    //         author: "Ana Barreto",
    //         response: "Muito Obrigada.",
    //       },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     author: "Pedro Campos",
    //     comment: "Ficou ótimo!",
    //     responses: [
    //       {
    //         author: "Ana Barreto",
    //         response: "Muito Obrigada.",
    //       },
    //     ],
    //   },
    //   {
    //     id: 3,
    //     author: "Joãozin",
    //     comment: "Linda demais!",
    //     responses: [
    //       {
    //         author: "Ana Barreto",
    //         response: "Muito Obrigada.",
    //       },
    //     ],
    //   },
    //   {
    //     id: 4,
    //     author: "Leonardo",
    //     comment: "Ficou top!",
    //     responses: [
    //       {
    //         author: "Ana Barreto",
    //         response: "Muito Obrigada.",
    //       },
    //     ],
    //   },
    // ],
    comments: "100",
    datePublication: dayjs("2020-08-21 15:00:00"), // datePublication
  },
  {
    affiliated: "Clara Alves",
    likes: 50,
    category: "topless",
    urlProfile: require("@images/janis.jpg"),
    urlImage: require("@images/janis.jpg"),
    urlVideo: "",
    postText:
      "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur",
    comments: "100",
    datePublication: dayjs("2020-08-21 10:00:00"),
  },
  {
    affiliated: "Cecilia Guimarães",
    likes: 200,
    category: "sexy",
    urlProfile: require("../../images/emily.jpg"),
    urlImage: "",
    urlVideo: require("@images/Laryssa.mp4"),
    postText: "Lorem ipsum dolor sit amet consectetur",
    comments: "100",
    datePublication: dayjs("2020-08-16 13:00:00"),
  },
  {
    affiliated: "Ana Barreto 2",
    likes: 100,
    category: "sexy",
    urlProfile: require("@images/lais.jpg"),
    urlImage: require("@images/lais.jpg"),
    urlVideo: "",
    postText: "Lorem ipsum dolor sit amet consectetur",
    comments: "100",
    datePublication: dayjs("2020-08-21 15:00:00"),
  },
  {
    affiliated: "Clara Alves 2",
    likes: 50,
    category: "topless",
    urlProfile: require("../../images/ana.jpg"),
    urlImage: require("@images/ana.jpg"),
    urlVideo: "",
    postText:
      "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur",
    comments: "100",
    datePublication: dayjs("2020-08-21 10:00:00"),
  },
  {
    affiliated: "Cecilia Guimarães 2",
    likes: 200,
    category: "topless",
    urlProfile: require("../../images/emily.jpg"),
    urlImage: "",
    urlVideo: require("@images/Laryssa.mp4"),
    postText: "Lorem ipsum dolor sit amet consectetur",
    comments: "100",
    datePublication: dayjs("2020-08-16 13:00:00"),
  },
  {
    affiliated: "Ana Barreto 3",
    likes: 100,
    category: "nudes",
    urlProfile: require("@images/ani.jpg"),
    urlImage: require("@images/ani.jpg"),
    urlVideo: "",
    postText: "Lorem ipsum dolor sit amet consectetur",
    comments: "100",
    datePublication: dayjs("2020-08-21 15:00:00"),
  },
  {
    affiliated: "Clara Alves 3",
    likes: 50,
    category: "nudes",
    urlProfile: require("../../images/ana.jpg"),
    urlImage: "",
    urlVideo: require("@images/Laryssa.mp4"),
    postText:
      "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur",
    datePublication: dayjs("2020-08-21 10:00:00"),
    comments: "100",
  },
  {
    affiliated: "Cecilia Guimarães 3",
    likes: 200,
    category: "nudes",
    urlProfile: require("../../images/emily.jpg"),
    urlImage: "",
    urlVideo: require("@images/Laryssa.mp4"),
    postText: "Lorem ipsum dolor sit amet consectetur",
    comments: "100",
    datePublication: dayjs("2020-08-16 13:00:00"),
  },
  {
    affiliated: "Fernanda Dantas",
    likes: 200,
    urlProfile:
      "https://d1a0n9gptf7ayu.cloudfront.net/cache/70/98/70980b29cbfbf24fbcf6f111de06ab86.jpg",
    urlImage:
      "https://d1a0n9gptf7ayu.cloudfront.net/cache/70/98/70980b29cbfbf24fbcf6f111de06ab86.jpg",
    urlVideo: "",
    postText:
      "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur",
    comments: "100",
    datePublication: dayjs("2020-08-16 13:00:00"),
  },
  {
    affiliated: "Clara Alves 4",
    likes: 50,
    category: "sensual",
    urlProfile: require("../../images/ana.jpg"),
    urlImage: require("@images/ana.jpg"),
    urlVideo: "",
    postText:
      "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur",
    comments: "100",
    datePublication: dayjs("2020-08-21 10:00:00"),
  },
]
